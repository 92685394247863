import React, { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useGetCart } from '../CartQuery/CartQuery'
import { CloseButton } from '@local/do-secundo-closebutton'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { Loading } from '@toasttab/do-secundo-loading'
import { MessageError } from '@toasttab/do-secundo-message-error'
import { ToastContainer } from 'react-toastify'
import { CheckoutForm } from '../CheckoutForm/CheckoutForm'
import { CreditCardProvider } from '../CreditCardProvider/CreditCardProvider'
import { RestaurantInfo } from '../RestaurantInfo/RestaurantInfo'
import { ScrollToTop } from '../ScrollToTop/ScrollToTop'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { CartModificationError } from '../Cart/CartModificationError/CartModificationError'
import { useServiceAvailability } from '../ServiceAvailabilityProvider/ServiceAvaialbilityProvider'
import { ValidateCart } from '../ValidateCart/ValidateCart'
import style from './CheckoutPage.module.css'
import { useAuthorizeCard } from './useAuthorizeCard'
import { handlePlaceOrder } from './utils'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { useReCaptcha } from '../../hooks/use-recaptcha'
import { InstagramWarning } from '../RestaurantPage/InstagramWarning'

const { toastContainer, toastWrapper } = style

export const CheckoutPage = () => {
  const [errorMessage, setErrorMessage] = useState<Error | string>()
  const { logCriticalError } = useServiceAvailability()
  const { orderingAvailable } = useAvailability()
  const { getRestaurantPath } = useRestaurant()
  const { generateReCaptchaToken } = useReCaptcha()
  const { doAuthorization } = useAuthorizeCard()
  const { fastLinkName } = useFulfillment()
  const navigate = useNavigate()

  const {
    error: getCartError,
    loading,
    cart,
    deleteCartGuid,
    refetch
  } = useGetCart()

  if (loading) {
    return (
      <div className='mt-8 md:mt-10'>
        <Loading />
      </div>
    )
  }

  if (getCartError) {
    return (
      <div className='px-10 mt-20'>
        <MessageError
          header='Unable to load checkout'
          message='Try refreshing the page, or contact the restaurant directly to order'
          buttonSecondary={{
            label: 'Refresh page',
            onClick: () => refetch()
          }}
          testId='checkout-load-error'
        />
      </div>
    )
  }

  if (!cart) {
    return <Navigate to={getRestaurantPath()} replace />
  }

  return (
    <CreditCardProvider>
      <div className={style.page} role='main' aria-label='checkout'>
        <ScrollToTop />
        <InstagramWarning />
        <RestaurantInfo
          header='Checkout'
          orderingAvailable={orderingAvailable}
        />
        <div className={style.cartButton}>
          <CloseButton
            data-testid='close-button-checkout-page'
            to={getRestaurantPath('cart')}
          />
        </div>
        <Link
          to={getRestaurantPath()}
          data-testid='back-to-menu-link'
          className={style.menuButton}
        >
          Back to menu
        </Link>
        <ValidateCart />
        <CartModificationError />
        <CheckoutForm
          error={errorMessage}
          onSubmit={async ({
            ccInput,
            paymentType,
            passwordlessFlow = false,
            formValues,
            loyaltyEnroll,
            taxExemptId,
            taxExemptState,
            companyName,
            shouldLookupLoyalty
          }) => {
            try {
              await handlePlaceOrder({
                cart,
                ccInput,
                paymentType,
                generateReCaptchaToken,
                doAuthorization,
                navigateOnSuccess: (guid: string) =>
                  navigate(getRestaurantPath(`confirm/${guid}`), {
                    replace: true
                  }),
                deleteCartGuid,
                setErrorMessage,
                //@ts-ignore
                logCriticalError,
                passwordlessFlow,
                formValues,
                loyaltyEnroll,
                taxExemptId,
                taxExemptState,
                companyName,
                shouldLookupLoyalty,
                fastLinkName: fastLinkName
              })
            } catch (err) {
              console.warn(err)
            }
          }}
        />
      </div>
      <ToastContainer
        hideProgressBar
        closeButton={false}
        className={toastContainer}
        toastClassName={toastWrapper}
        position='top-center'
      />
    </CreditCardProvider>
  )
}
