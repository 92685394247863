import * as React from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { AvailabilityProvider } from '@local/do-secundo-availability-provider'
import { CartProvider } from '@local/do-secundo-cart-provider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useRestaurantStorage } from '../../utils/restaurant-storage'
import { CartPage } from '../CartPage/CartPage'
import { CheckoutPage } from '../CheckoutPage/CheckoutPage'
import { ConfirmPage } from '../ConfirmPage/ConfirmPage'
import { FulfillmentProvider } from '../FulfillmentProvider/FulfillmentProvider'
import { Header } from '../Header/Header'
import MenuPage from '../MenuPage/MenuPage'
import { ModeRouter } from '../ModeRouter/ModeRouter'
import { RumRoute } from '../RumRoute'
import { Spotlight } from '../RestaurantHeader/Spotlight/Spotlight'
import { useEffect } from 'react'
import { renderStyleMeta } from '@toasttab/sites-components'
import { getImageUrl } from '../RestaurantHeader/RestaurantLogo'
import { brandingPublicAssetURL } from '../../api/util'
import { InstagramWarning } from './InstagramWarning'

export const RestaurantPage = () => {
  const {
    restaurantGuid,
    setBasePath,
    applicableConfigs: { brandingConfig },
    updateApplicableConfigsState
  } = useRestaurant()

  const { fastLinkName: fastLinkNameEncoded } = useParams<{
    fastLinkName: string | undefined
  }>()
  const fastLinkName = fastLinkNameEncoded
    ? decodeURIComponent(fastLinkNameEncoded)
    : undefined

  useEffect(() => {
    if (fastLinkName) {
      setBasePath(`/menu/${encodeURIComponent(fastLinkName)}/`)
      updateApplicableConfigsState({ name: fastLinkName })
    } else {
      setBasePath('/')
      updateApplicableConfigsState({ name: undefined })
    }
  }, [fastLinkName])

  useEffect(() => {
    if (brandingConfig?.enabled && brandingConfig.favicon) {
      let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']")
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }
      link.href = getImageUrl(brandingPublicAssetURL, brandingConfig.favicon)!!
    }
  }, [brandingConfig])

  useEffect(() => {
    document.title += ' - Order Catering'
  }, [])

  const restaurantStorage = useRestaurantStorage()

  const addItemRoute = `/items/add/:itemGuid/:itemGroupGuid`

  return (
    <AvailabilityProvider fastLinkName={fastLinkName}>
      <CartProvider
        restaurantGuid={restaurantGuid}
        restaurantStorage={restaurantStorage}
        fastLinkName={fastLinkName}
      >
        <>
          <Helmet>
            {renderStyleMeta({ ...brandingConfig }, brandingPublicAssetURL)}
          </Helmet>
          <Routes>
            <Route path={'/'} element={<Spotlight />} />
            <Route path={addItemRoute} element={<Spotlight />} />
          </Routes>
          <FulfillmentProvider fastLinkName={fastLinkName}>
            <Header />
            <InstagramWarning />
            <ModeRouter />
            <Routes>
              <Route
                path={`cart`}
                element={
                  <RumRoute>
                    <CartPage />
                  </RumRoute>
                }
              />
              <Route
                path={`checkout`}
                element={
                  <RumRoute>
                    <CheckoutPage />
                  </RumRoute>
                }
              />
              <Route
                path={`confirm/:orderGuid`}
                element={
                  <RumRoute>
                    <ConfirmPage />
                  </RumRoute>
                }
              />
              <Route
                path={'/'}
                element={
                  <RumRoute>
                    <MenuPage />
                  </RumRoute>
                }
              />
              <Route
                path={addItemRoute}
                element={
                  <RumRoute>
                    <MenuPage />
                  </RumRoute>
                }
              />
              <Route path={'*'} element={<Navigate to='/' replace />} />
            </Routes>
          </FulfillmentProvider>
        </>
      </CartProvider>
    </AvailabilityProvider>
  )
}
